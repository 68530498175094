import { SVGProps } from 'react';

const Success = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M19.6767 4.22177C19.039 3.80823 18.1876 3.98978 17.7745 4.62705L10.0676 16.5065L6.30911 13.0313C5.75115 12.5155 4.88143 12.5494 4.36565 13.1074C3.84988 13.6649 3.8838 14.5355 4.44176 15.0508L9.37673 19.6135C9.37673 19.6135 9.5184 19.7355 9.58396 19.7781C9.81503 19.9285 10.0745 20 10.3313 20C10.7815 20 11.223 19.779 11.4866 19.3728L20.082 6.12396C20.4955 5.48668 20.314 4.63485 19.6767 4.22177Z"
      fill="currentColor"
    />
  </svg>
);

export default Success;
