"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.adminConsoleConfigGuard = exports.AppearanceMode = exports.connectorTargetsGuard = exports.signInMethodsGuard = exports.SignInMethodState = exports.SignInMethodKey = exports.languageInfoGuard = exports.termsOfUseGuard = exports.brandingGuard = exports.BrandingStyle = exports.colorGuard = exports.identitiesGuard = exports.roleNamesGuard = exports.customClientMetadataGuard = exports.CustomClientMetadataKey = exports.oidcClientMetadataGuard = exports.oidcModelInstancePayloadGuard = exports.arbitraryObjectGuard = void 0;
const phrases_ui_1 = require("@logto/phrases-ui");
const shared_1 = require("@logto/shared");
const zod_1 = require("zod");
/**
 * Commonly Used
 */
// Cannot declare `z.object({}).catchall(z.unknown().optional())` to guard `{ [key: string]?: unknown }` (invalid type),
// so do it another way to guard `{ [x: string]: unknown; } | {}`.
exports.arbitraryObjectGuard = zod_1.z.union([zod_1.z.object({}).catchall(zod_1.z.unknown()), zod_1.z.object({})]);
/**
 * OIDC Model Instances
 */
exports.oidcModelInstancePayloadGuard = zod_1.z
    .object({
    userCode: zod_1.z.string().optional(),
    uid: zod_1.z.string().optional(),
    grantId: zod_1.z.string().optional(),
})
    /**
     * Try to use `.passthrough()` if type has been fixed.
     * https://github.com/colinhacks/zod/issues/452
     */
    .catchall(zod_1.z.unknown());
exports.oidcClientMetadataGuard = zod_1.z.object({
    redirectUris: zod_1.z.string().url().array(),
    postLogoutRedirectUris: zod_1.z.string().url().array(),
    logoUri: zod_1.z.string().optional(),
});
var CustomClientMetadataKey;
(function (CustomClientMetadataKey) {
    CustomClientMetadataKey["CorsAllowedOrigins"] = "corsAllowedOrigins";
    CustomClientMetadataKey["IdTokenTtl"] = "idTokenTtl";
    CustomClientMetadataKey["RefreshTokenTtl"] = "refreshTokenTtl";
})(CustomClientMetadataKey = exports.CustomClientMetadataKey || (exports.CustomClientMetadataKey = {}));
exports.customClientMetadataGuard = zod_1.z.object({
    [CustomClientMetadataKey.CorsAllowedOrigins]: zod_1.z.string().url().array().optional(),
    [CustomClientMetadataKey.IdTokenTtl]: zod_1.z.number().optional(),
    [CustomClientMetadataKey.RefreshTokenTtl]: zod_1.z.number().optional(),
});
/**
 * Users
 */
exports.roleNamesGuard = zod_1.z.string().array();
const identityGuard = zod_1.z.object({
    userId: zod_1.z.string(),
    details: zod_1.z.object({}).optional(), // Connector's userinfo details, schemaless
});
exports.identitiesGuard = zod_1.z.record(identityGuard);
/**
 * SignIn Experiences
 */
exports.colorGuard = zod_1.z.object({
    primaryColor: zod_1.z.string().regex(shared_1.hexColorRegEx),
    isDarkModeEnabled: zod_1.z.boolean(),
    darkPrimaryColor: zod_1.z.string().regex(shared_1.hexColorRegEx),
});
var BrandingStyle;
(function (BrandingStyle) {
    BrandingStyle["Logo"] = "Logo";
    BrandingStyle["Logo_Slogan"] = "Logo_Slogan";
})(BrandingStyle = exports.BrandingStyle || (exports.BrandingStyle = {}));
exports.brandingGuard = zod_1.z.object({
    style: zod_1.z.nativeEnum(BrandingStyle),
    logoUrl: zod_1.z.string().url(),
    darkLogoUrl: zod_1.z.string().url().optional(),
    slogan: zod_1.z.string().optional(),
});
exports.termsOfUseGuard = zod_1.z.object({
    enabled: zod_1.z.boolean(),
    contentUrl: zod_1.z.string().url().optional().or(zod_1.z.literal('')),
});
exports.languageInfoGuard = zod_1.z.object({
    autoDetect: zod_1.z.boolean(),
    fallbackLanguage: zod_1.z.nativeEnum(phrases_ui_1.Language),
    fixedLanguage: zod_1.z.nativeEnum(phrases_ui_1.Language),
});
var SignInMethodKey;
(function (SignInMethodKey) {
    SignInMethodKey["Username"] = "username";
    SignInMethodKey["Email"] = "email";
    SignInMethodKey["SMS"] = "sms";
    SignInMethodKey["Social"] = "social";
})(SignInMethodKey = exports.SignInMethodKey || (exports.SignInMethodKey = {}));
var SignInMethodState;
(function (SignInMethodState) {
    SignInMethodState["Primary"] = "primary";
    SignInMethodState["Secondary"] = "secondary";
    SignInMethodState["Disabled"] = "disabled";
})(SignInMethodState = exports.SignInMethodState || (exports.SignInMethodState = {}));
exports.signInMethodsGuard = zod_1.z.object({
    [SignInMethodKey.Username]: zod_1.z.nativeEnum(SignInMethodState),
    [SignInMethodKey.Email]: zod_1.z.nativeEnum(SignInMethodState),
    [SignInMethodKey.SMS]: zod_1.z.nativeEnum(SignInMethodState),
    [SignInMethodKey.Social]: zod_1.z.nativeEnum(SignInMethodState),
});
exports.connectorTargetsGuard = zod_1.z.string().array();
/**
 * Settings
 */
var AppearanceMode;
(function (AppearanceMode) {
    AppearanceMode["SyncWithSystem"] = "system";
    AppearanceMode["LightMode"] = "light";
    AppearanceMode["DarkMode"] = "dark";
})(AppearanceMode = exports.AppearanceMode || (exports.AppearanceMode = {}));
exports.adminConsoleConfigGuard = zod_1.z.object({
    // Get started challenges
    demoChecked: zod_1.z.boolean(),
    applicationCreated: zod_1.z.boolean(),
    signInExperienceCustomized: zod_1.z.boolean(),
    passwordlessConfigured: zod_1.z.boolean(),
    socialSignInConfigured: zod_1.z.boolean(),
    furtherReadingsChecked: zod_1.z.boolean(),
});
