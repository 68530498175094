import { SVGProps } from 'react';

export const KeyboardArrowDown = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12.71 15.5402L18.36 9.88023C18.4537 9.78726 18.5281 9.67666 18.5789 9.5548C18.6296 9.43294 18.6558 9.30224 18.6558 9.17023C18.6558 9.03821 18.6296 8.90751 18.5789 8.78565C18.5281 8.66379 18.4537 8.55319 18.36 8.46023C18.1726 8.27398 17.9191 8.16943 17.655 8.16943C17.3908 8.16943 17.1373 8.27398 16.95 8.46023L11.95 13.4102L6.99996 8.46023C6.8126 8.27398 6.55915 8.16943 6.29496 8.16943C6.03078 8.16943 5.77733 8.27398 5.58996 8.46023C5.49548 8.55284 5.42031 8.66329 5.36881 8.78516C5.31731 8.90704 5.29051 9.03792 5.28996 9.17023C5.29051 9.30253 5.31731 9.43342 5.36881 9.55529C5.42031 9.67717 5.49548 9.78761 5.58996 9.88023L11.24 15.5402C11.3336 15.6417 11.4473 15.7227 11.5738 15.7781C11.7003 15.8336 11.8369 15.8622 11.975 15.8622C12.1131 15.8622 12.2497 15.8336 12.3762 15.7781C12.5027 15.7227 12.6163 15.6417 12.71 15.5402Z"
      fill="currentColor"
    />
  </svg>
);

export const KeyboardArrowUp = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M11.29 8.45977L5.64004 14.1198C5.54631 14.2127 5.47191 14.3233 5.42115 14.4452C5.37038 14.5671 5.34424 14.6978 5.34424 14.8298C5.34424 14.9618 5.37038 15.0925 5.42115 15.2144C5.47191 15.3362 5.54631 15.4468 5.64004 15.5398C5.8274 15.726 6.08085 15.8306 6.34504 15.8306C6.60922 15.8306 6.86267 15.726 7.05004 15.5398L12.05 10.5898L17 15.5398C17.1874 15.726 17.4409 15.8306 17.705 15.8306C17.9692 15.8306 18.2227 15.726 18.41 15.5398C18.5045 15.4472 18.5797 15.3367 18.6312 15.2148C18.6827 15.093 18.7095 14.9621 18.71 14.8298C18.7095 14.6975 18.6827 14.5666 18.6312 14.4447C18.5797 14.3228 18.5045 14.2124 18.41 14.1198L12.76 8.45977C12.6664 8.35827 12.5527 8.27726 12.4262 8.22185C12.2997 8.16645 12.1631 8.13784 12.025 8.13784C11.8869 8.13784 11.7503 8.16645 11.6238 8.22185C11.4973 8.27726 11.3837 8.35827 11.29 8.45977Z"
      fill="currentColor"
    />
  </svg>
);

export const ArrowUp = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M14.7578 9.41199L10.5912 5.24532C10.5119 5.16946 10.4185 5.10999 10.3162 5.07032C10.1133 4.98698 9.88572 4.98698 9.68284 5.07032C9.58054 5.10999 9.48709 5.16946 9.40784 5.24532L5.24117 9.41199C5.16347 9.48969 5.10184 9.58193 5.05979 9.68345C5.01774 9.78497 4.99609 9.89377 4.99609 10.0037C4.99609 10.2256 5.08425 10.4384 5.24117 10.5953C5.39809 10.7522 5.61092 10.8404 5.83284 10.8404C6.05475 10.8404 6.26758 10.7522 6.4245 10.5953L9.16617 7.84532V14.1703C9.16617 14.3913 9.25397 14.6033 9.41025 14.7596C9.56653 14.9159 9.77849 15.0037 9.9995 15.0037C10.2205 15.0037 10.4325 14.9159 10.5888 14.7596C10.745 14.6033 10.8328 14.3913 10.8328 14.1703V7.84532L13.5745 10.5953C13.652 10.6734 13.7441 10.7354 13.8457 10.7777C13.9472 10.82 14.0562 10.8418 14.1662 10.8418C14.2762 10.8418 14.3851 10.82 14.4867 10.7777C14.5882 10.7354 14.6804 10.6734 14.7578 10.5953C14.8359 10.5179 14.8979 10.4257 14.9402 10.3241C14.9826 10.2226 15.0043 10.1137 15.0043 10.0037C15.0043 9.89365 14.9826 9.78473 14.9402 9.68318C14.8979 9.58163 14.8359 9.48946 14.7578 9.41199Z"
      fill="currentColor"
    />
  </svg>
);

export const ArrowDown = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M14.7578 10.588L10.5912 14.7547C10.5119 14.8305 10.4185 14.89 10.3162 14.9297C10.1133 15.013 9.88572 15.013 9.68284 14.9297C9.58054 14.89 9.48709 14.8305 9.40784 14.7547L5.24117 10.588C5.16347 10.5103 5.10184 10.4181 5.05979 10.3166C5.01774 10.215 4.99609 10.1062 4.99609 9.99634C4.99609 9.77442 5.08425 9.5616 5.24117 9.40468C5.39809 9.24776 5.61092 9.1596 5.83284 9.1596C6.05475 9.1596 6.26758 9.24776 6.4245 9.40468L9.16617 12.1547V5.82968C9.16617 5.60866 9.25397 5.3967 9.41025 5.24042C9.56653 5.08414 9.77849 4.99634 9.9995 4.99634C10.2205 4.99634 10.4325 5.08414 10.5888 5.24042C10.745 5.3967 10.8328 5.60866 10.8328 5.82968V12.1547L13.5745 9.40468C13.652 9.32657 13.7441 9.26457 13.8457 9.22227C13.9472 9.17996 14.0562 9.15818 14.1662 9.15818C14.2762 9.15818 14.3851 9.17996 14.4867 9.22227C14.5882 9.26457 14.6804 9.32657 14.7578 9.40468C14.8359 9.48214 14.8979 9.57431 14.9402 9.67586C14.9826 9.77741 15.0043 9.88633 15.0043 9.99634C15.0043 10.1064 14.9826 10.2153 14.9402 10.3168C14.8979 10.4184 14.8359 10.5105 14.7578 10.588Z"
      fill="currentColor"
    />
  </svg>
);
