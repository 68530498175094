"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const demo_app = {
    notification: 'Use your admin console account to sign in to this demo app.',
    title: "You've successfully signed in the demo app!",
    subtitle: 'Here is your log in information:',
    username: 'Username: ',
    user_id: 'User ID: ',
    sign_out: 'Sign Out the Demo App',
    continue_explore: 'Or continue to explore',
    customize_sign_in_experience: 'Customize Sign-in Experience',
    enable_passwordless: 'Enable Passwordless',
    add_social_connector: 'Add Social Connector',
};
exports.default = demo_app;
