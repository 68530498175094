"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const settings = {
    title: 'Settings',
    description: 'Manage the global settings',
    tabs: {
        general: 'General',
    },
    custom_domain: 'Custom domain',
    language: 'Language',
    language_english: 'English',
    language_chinese: 'Chinese',
    appearance: 'Appearance',
    appearance_system: 'Sync with system',
    appearance_light: 'Light mode',
    appearance_dark: 'Dark mode',
    saved: 'Saved!',
    change_password: 'Change Password',
    change_password_description: 'You can change password for this account. You will use current username with new password to sign in Admin Console.',
    change_modal_title: 'Change Account Password',
    change_modal_description: 'You will use current username with new password to sign in Admin Console.',
    new_password: 'New password',
    new_password_placeholder: 'Enter your password',
    confirm_password: 'Confirm password',
    confirm_password_placeholder: 'Confirm your password',
    password_changed: 'Password changed!',
};
exports.default = settings;
