"use strict";
// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
Object.defineProperty(exports, "__esModule", { value: true });
exports.Passcodes = void 0;
const zod_1 = require("zod");
const custom_types_1 = require("./custom-types");
const createGuard = zod_1.z.object({
    id: zod_1.z.string(),
    interactionJti: zod_1.z.string(),
    phone: zod_1.z.string().nullable().optional(),
    email: zod_1.z.string().nullable().optional(),
    type: zod_1.z.nativeEnum(custom_types_1.PasscodeType),
    code: zod_1.z.string(),
    consumed: zod_1.z.boolean().optional(),
    tryCount: zod_1.z.number().optional(),
    createdAt: zod_1.z.number().optional(),
});
const guard = zod_1.z.object({
    id: zod_1.z.string(),
    interactionJti: zod_1.z.string(),
    phone: zod_1.z.string().nullable(),
    email: zod_1.z.string().nullable(),
    type: zod_1.z.nativeEnum(custom_types_1.PasscodeType),
    code: zod_1.z.string(),
    consumed: zod_1.z.boolean(),
    tryCount: zod_1.z.number(),
    createdAt: zod_1.z.number(),
});
exports.Passcodes = Object.freeze({
    table: 'passcodes',
    tableSingular: 'passcode',
    fields: {
        id: 'id',
        interactionJti: 'interaction_jti',
        phone: 'phone',
        email: 'email',
        type: 'type',
        code: 'code',
        consumed: 'consumed',
        tryCount: 'try_count',
        createdAt: 'created_at',
    },
    fieldKeys: [
        'id',
        'interactionJti',
        'phone',
        'email',
        'type',
        'code',
        'consumed',
        'tryCount',
        'createdAt',
    ],
    createGuard,
    guard,
});
