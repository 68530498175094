"use strict";
// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
Object.defineProperty(exports, "__esModule", { value: true });
exports.Applications = void 0;
const zod_1 = require("zod");
const foundations_1 = require("../foundations");
const custom_types_1 = require("./custom-types");
const createGuard = zod_1.z.object({
    id: zod_1.z.string(),
    name: zod_1.z.string(),
    description: zod_1.z.string().nullable().optional(),
    type: zod_1.z.nativeEnum(custom_types_1.ApplicationType),
    oidcClientMetadata: foundations_1.oidcClientMetadataGuard,
    customClientMetadata: foundations_1.customClientMetadataGuard.optional(),
    createdAt: zod_1.z.number().optional(),
});
const guard = zod_1.z.object({
    id: zod_1.z.string(),
    name: zod_1.z.string(),
    description: zod_1.z.string().nullable(),
    type: zod_1.z.nativeEnum(custom_types_1.ApplicationType),
    oidcClientMetadata: foundations_1.oidcClientMetadataGuard,
    customClientMetadata: foundations_1.customClientMetadataGuard,
    createdAt: zod_1.z.number(),
});
exports.Applications = Object.freeze({
    table: 'applications',
    tableSingular: 'application',
    fields: {
        id: 'id',
        name: 'name',
        description: 'description',
        type: 'type',
        oidcClientMetadata: 'oidc_client_metadata',
        customClientMetadata: 'custom_client_metadata',
        createdAt: 'created_at',
    },
    fieldKeys: [
        'id',
        'name',
        'description',
        'type',
        'oidcClientMetadata',
        'customClientMetadata',
        'createdAt',
    ],
    createGuard,
    guard,
});
