@use '@/scss/underscore' as _;

.container {
  height: 100%;
  display: flex;
  flex-direction: column;
  min-width: min-content;

  > *:not(:first-child) {
    margin-top: _.unit(4);
  }

  .body {
    position: relative;
    padding-bottom: 0;
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  .footer {
    position: sticky;
    bottom: 0;
    margin: 0 _.unit(-6);
    // Use the same color with app's background to cover card body
    // simulate the always-on border-radius
    background: var(--color-base);

    .footerMain {
      display: flex;
      justify-content: flex-end;
      border-bottom-left-radius: 16px;
      border-bottom-right-radius: 16px;
      background: var(--color-layer-1);
      padding: _.unit(6);
    }

    &::before {
      content: '';
      background: var(--color-border);
      opacity: 50%;
      height: 1px;
      display: block;
      margin: 0;
    }
  }
}
