"use strict";
/* eslint-disable @typescript-eslint/consistent-indexed-object-style */
Object.defineProperty(exports, "__esModule", { value: true });
exports.Language = void 0;
var Language;
(function (Language) {
    Language["English"] = "en";
    Language["Chinese"] = "zh-CN";
})(Language = exports.Language || (exports.Language = {}));
/* eslint-enable @typescript-eslint/consistent-indexed-object-style */
