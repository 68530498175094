@use '@/scss/underscore' as _;

.button {
  display: inline-block;
  font: var(--font-body-medium);
  color: var(--color-text-link);
  padding: _.unit(0.5) _.unit(1);
  border-radius: _.unit(1);
  text-decoration: none;
  cursor: pointer;

  svg {
    color: var(--color-primary);
  }

  &:hover {
    text-decoration: underline;
  }

  &:focus {
    outline: 2px solid var(--color-focused-variant);
  }
}
