"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createDemoAppApplication = exports.demoAppApplicationId = exports.adminConsoleApplicationId = void 0;
const db_entries_1 = require("../db-entries");
/**
 * The fixed application ID for Admin Console.
 *
 * This built-in application does not belong to any tenant in the OSS version.
 */
exports.adminConsoleApplicationId = 'admin-console';
exports.demoAppApplicationId = 'demo-app';
const createDemoAppApplication = () => ({
    id: exports.demoAppApplicationId,
    name: 'Demo App',
    description: 'Logto demo app.',
    type: db_entries_1.ApplicationType.SPA,
    oidcClientMetadata: { redirectUris: [], postLogoutRedirectUris: [] },
});
exports.createDemoAppApplication = createDemoAppApplication;
