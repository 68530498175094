"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TokenType = exports.LogResult = void 0;
var LogResult;
(function (LogResult) {
    LogResult["Success"] = "Success";
    LogResult["Error"] = "Error";
})(LogResult = exports.LogResult || (exports.LogResult = {}));
var TokenType;
(function (TokenType) {
    TokenType["AccessToken"] = "AccessToken";
    TokenType["RefreshToken"] = "RefreshToken";
    TokenType["IdToken"] = "IdToken";
})(TokenType = exports.TokenType || (exports.TokenType = {}));
