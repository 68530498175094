import { SVGProps } from 'react';

const Eye = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="18"
    height="12"
    viewBox="0 0 18 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M11.5003 5.99996C11.5003 7.38067 10.381 8.49996 9.00033 8.49996C7.61961 8.49996 6.50033 7.38067 6.50033 5.99996C6.50033 4.61925 7.61961 3.49996 9.00033 3.49996C10.381 3.49996 11.5003 4.61925 11.5003 5.99996Z"
      fill="currentColor"
    />
    <path
      d="M17.2457 5.62728C15.4472 2.03025 12.2529 0.166626 9.00033 0.166626C5.74772 0.166626 2.55349 2.03025 0.75497 5.62728C0.637666 5.86189 0.637666 6.13803 0.75497 6.37264C2.55349 9.96967 5.74772 11.8333 9.00033 11.8333C12.2529 11.8333 15.4472 9.96967 17.2457 6.37264C17.363 6.13803 17.363 5.86189 17.2457 5.62728ZM9.00033 10.1666C6.52634 10.1666 3.99871 8.81597 2.44171 5.99996C3.99871 3.18394 6.52634 1.83329 9.00033 1.83329C11.4743 1.83329 14.0019 3.18394 15.5589 5.99996C14.0019 8.81597 11.4743 10.1666 9.00033 10.1666Z"
      fill="currentColor"
    />
  </svg>
);

export default Eye;
