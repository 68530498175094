@use '@/scss/underscore' as _;

.item {
  display: flex;
  align-items: center;
  white-space: nowrap;

  > div:not(:first-child) {
    margin-left: _.unit(3);
  }

  .content {
    padding-right: _.unit(4);
    max-width: 100%;

    .title {
      font: var(--font-body-medium);
      color: var(--color-text-link);
      text-decoration: none;
    }

    .subtitle {
      font: var(--font-body-small);
      color: var(--color-outline);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &.compact {
    .content {
      display: flex;
      align-items: baseline;

      .title {
        margin-right: _.unit(1);
      }
    }
  }
}
