"use strict";
// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
Object.defineProperty(exports, "__esModule", { value: true });
exports.SignInExperiences = void 0;
const zod_1 = require("zod");
const foundations_1 = require("../foundations");
const custom_types_1 = require("./custom-types");
const createGuard = zod_1.z.object({
    id: zod_1.z.string(),
    color: foundations_1.colorGuard,
    branding: foundations_1.brandingGuard,
    languageInfo: foundations_1.languageInfoGuard,
    termsOfUse: foundations_1.termsOfUseGuard,
    signInMethods: foundations_1.signInMethodsGuard,
    socialSignInConnectorTargets: foundations_1.connectorTargetsGuard.optional(),
    signInMode: zod_1.z.nativeEnum(custom_types_1.SignInMode).optional(),
});
const guard = zod_1.z.object({
    id: zod_1.z.string(),
    color: foundations_1.colorGuard,
    branding: foundations_1.brandingGuard,
    languageInfo: foundations_1.languageInfoGuard,
    termsOfUse: foundations_1.termsOfUseGuard,
    signInMethods: foundations_1.signInMethodsGuard,
    socialSignInConnectorTargets: foundations_1.connectorTargetsGuard,
    signInMode: zod_1.z.nativeEnum(custom_types_1.SignInMode),
});
exports.SignInExperiences = Object.freeze({
    table: 'sign_in_experiences',
    tableSingular: 'sign_in_experience',
    fields: {
        id: 'id',
        color: 'color',
        branding: 'branding',
        languageInfo: 'language_info',
        termsOfUse: 'terms_of_use',
        signInMethods: 'sign_in_methods',
        socialSignInConnectorTargets: 'social_sign_in_connector_targets',
        signInMode: 'sign_in_mode',
    },
    fieldKeys: [
        'id',
        'color',
        'branding',
        'languageInfo',
        'termsOfUse',
        'signInMethods',
        'socialSignInConnectorTargets',
        'signInMode',
    ],
    createGuard,
    guard,
});
