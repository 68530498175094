"use strict";
// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
Object.defineProperty(exports, "__esModule", { value: true });
exports.Logs = void 0;
const zod_1 = require("zod");
const foundations_1 = require("../foundations");
const createGuard = zod_1.z.object({
    id: zod_1.z.string(),
    type: zod_1.z.string(),
    payload: foundations_1.arbitraryObjectGuard.optional(),
    createdAt: zod_1.z.number().optional(),
});
const guard = zod_1.z.object({
    id: zod_1.z.string(),
    type: zod_1.z.string(),
    payload: foundations_1.arbitraryObjectGuard,
    createdAt: zod_1.z.number(),
});
exports.Logs = Object.freeze({
    table: 'logs',
    tableSingular: 'log',
    fields: {
        id: 'id',
        type: 'type',
        payload: 'payload',
        createdAt: 'created_at',
    },
    fieldKeys: ['id', 'type', 'payload', 'createdAt'],
    createGuard,
    guard,
});
