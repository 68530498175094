"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getFileContents = void 0;
const fs_1 = require("fs");
const getFileContents = (filePath, fallbackContent) => {
    if ((0, fs_1.existsSync)(filePath)) {
        return (0, fs_1.readFileSync)(filePath, 'utf8');
    }
    return fallbackContent;
};
exports.getFileContents = getFileContents;
