@use '@/scss/underscore' as _;

.body {
  padding-top: _.unit(5);

  .connector {
    font: var(--font-body-medium);
    width: 230px;

    .logo {
      width: 48px;
      height: 48px;
      border-radius: 8px;
      background-color: var(--color-hover);
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 34px;
        height: 34px;
      }
    }

    .name {
      margin-top: _.unit(2);
      font: var(--font-subhead-2);
    }

    .connectorId {
      margin-top: _.unit(2);
      font: var(--font-body-medium);
      color: var(--color-neutral-50);
    }

    .description {
      font: var(--font-body-medium);
      margin-top: _.unit(2);
      height: 60px;
      overflow: hidden;
    }
  }
}
