"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const settings = {
    title: '设置',
    description: '管理全局设置',
    tabs: {
        general: '通用',
    },
    custom_domain: '自定义域名',
    language: '语言',
    language_english: '英语',
    language_chinese: '中文',
    appearance: '外观',
    appearance_system: '跟随系统',
    appearance_light: '浅色模式',
    appearance_dark: '深色模式',
    saved: '已保存！',
    change_password: '修改密码',
    change_password_description: '修改本帐号密码。生效后使用当前用户名和新密码登录管理控制台。',
    change_modal_title: '修改帐号密码',
    change_modal_description: '生效后使用当前用户名和新密码登录管理控制台。',
    new_password: '新密码',
    new_password_placeholder: '输入密码',
    confirm_password: '确认密码',
    confirm_password_placeholder: '确认密码',
    password_changed: '密码已修改!',
};
exports.default = settings;
