@use '@/scss/underscore' as _;

.title {
  display: flex;
  align-items: center;

  .logo {
    margin-right: _.unit(2);
    width: 20px;
    height: 20px;

    img {
      width: 20px;
      height: 20px;
    }
  }

  .icon {
    width: 16px;
    height: 16px;
    object-fit: cover;
    margin-left: _.unit(1);
    color: var(--color-icon);
  }
}
