"use strict";
// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
Object.defineProperty(exports, "__esModule", { value: true });
exports.Roles = void 0;
const zod_1 = require("zod");
const createGuard = zod_1.z.object({
    name: zod_1.z.string(),
    description: zod_1.z.string(),
});
const guard = zod_1.z.object({
    name: zod_1.z.string(),
    description: zod_1.z.string(),
});
exports.Roles = Object.freeze({
    table: 'roles',
    tableSingular: 'role',
    fields: {
        name: 'name',
        description: 'description',
    },
    fieldKeys: ['name', 'description'],
    createGuard,
    guard,
});
