"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const general = {
    placeholder: 'Placeholder',
    skip: 'Skip',
    next: 'Next',
    retry: 'Try Again',
    done: 'Done',
    search: 'Search',
    search_placeholder: 'Search',
    clear_result: 'Clear Results',
    save: 'Save',
    save_changes: 'Save Changes',
    saved: 'Saved!',
    loading: 'Loading...',
    redirecting: 'Redirecting...',
    add: 'Add',
    added: 'Added',
    cancel: 'Cancel',
    confirm: 'Confirm',
    check_out: 'Check Out',
    create: 'Create',
    set_up: 'Set Up',
    customize: 'Customize',
    enable: 'Enable',
    reminder: 'Reminder',
    delete: 'Delete',
    more_options: 'MORE OPTIONS',
    close: 'Close',
    copy: 'Copy',
    copying: 'Copying',
    copied: 'Copied',
    required: 'Required',
    add_another: '+ Add Another',
    deletion_confirmation: 'Are you sure you want to delete this {{title}}?',
    settings_nav: 'Settings',
    unsaved_changes_warning: 'You have made some changes. Are you sure you want to leave this page?',
    leave_page: 'Leave Page',
    stay_on_page: 'Stay on Page',
};
exports.default = general;
