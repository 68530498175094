"use strict";
// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
Object.defineProperty(exports, "__esModule", { value: true });
exports.Connectors = void 0;
const zod_1 = require("zod");
const foundations_1 = require("../foundations");
const createGuard = zod_1.z.object({
    id: zod_1.z.string(),
    enabled: zod_1.z.boolean().optional(),
    config: foundations_1.arbitraryObjectGuard.optional(),
    createdAt: zod_1.z.number().optional(),
});
const guard = zod_1.z.object({
    id: zod_1.z.string(),
    enabled: zod_1.z.boolean(),
    config: foundations_1.arbitraryObjectGuard,
    createdAt: zod_1.z.number(),
});
exports.Connectors = Object.freeze({
    table: 'connectors',
    tableSingular: 'connector',
    fields: {
        id: 'id',
        enabled: 'enabled',
        config: 'config',
        createdAt: 'created_at',
    },
    fieldKeys: ['id', 'enabled', 'config', 'createdAt'],
    createGuard,
    guard,
});
