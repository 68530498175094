import { SVGProps } from 'react';

const Info = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M9.99984 1.66675C8.35166 1.66675 6.7405 2.15549 5.37009 3.07117C3.99968 3.98685 2.93158 5.28834 2.30084 6.81105C1.67011 8.33377 1.50509 10.0093 1.82663 11.6258C2.14817 13.2423 2.94185 14.7272 4.10728 15.8926C5.27272 17.0581 6.75758 17.8517 8.37409 18.1733C9.9906 18.4948 11.6662 18.3298 13.1889 17.6991C14.7116 17.0683 16.0131 16.0002 16.9288 14.6298C17.8444 13.2594 18.3332 11.6483 18.3332 10.0001C18.3332 8.90573 18.1176 7.8221 17.6988 6.81105C17.28 5.80001 16.6662 4.88135 15.8924 4.10752C15.1186 3.3337 14.1999 2.71987 13.1889 2.30109C12.1778 1.8823 11.0942 1.66675 9.99984 1.66675Z"
      fill="currentColor"
    />
    <path
      d="M9.41081 9.41066C9.56709 9.25438 9.77906 9.16658 10.0001 9.16658C10.2211 9.16658 10.433 9.25438 10.5893 9.41066C10.7456 9.56694 10.8334 9.7789 10.8334 9.99991V13.3332C10.8334 13.5543 10.7456 13.7662 10.5893 13.9225C10.433 14.0788 10.2211 14.1666 10.0001 14.1666C9.77906 14.1666 9.56709 14.0788 9.41081 13.9225C9.25453 13.7662 9.16674 13.5543 9.16674 13.3332V9.99991C9.16674 9.7789 9.25453 9.56694 9.41081 9.41066Z"
      fill="white"
    />
    <path
      d="M9.6834 5.89991C9.88629 5.81657 10.1139 5.81657 10.3167 5.89991C10.419 5.93958 10.5125 5.99905 10.5917 6.07491C10.744 6.23461 10.8303 6.44594 10.8334 6.66658C10.8327 6.80307 10.7985 6.9373 10.7338 7.05747C10.6691 7.17765 10.5759 7.28009 10.4623 7.3558C10.3488 7.43151 10.2183 7.47817 10.0825 7.49167C9.94671 7.50518 9.80965 7.48511 9.6834 7.43325C9.58242 7.39105 9.4894 7.33186 9.4084 7.25825C9.33117 7.18038 9.27006 7.08803 9.22859 6.9865C9.18712 6.88497 9.1661 6.77625 9.16674 6.66658C9.16392 6.55727 9.18675 6.44881 9.2334 6.34991C9.2756 6.24893 9.33479 6.15591 9.4084 6.07491C9.48766 5.99905 9.58111 5.93958 9.6834 5.89991Z"
      fill="white"
    />
  </svg>
);

export default Info;
