import { SVGProps } from 'react';

const More = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10.0002 5.83333C10.3298 5.83333 10.652 5.73559 10.9261 5.55245C11.2002 5.36931 11.4138 5.10902 11.54 4.80447C11.6661 4.49993 11.6991 4.16482 11.6348 3.84152C11.5705 3.51822 11.4118 3.22124 11.1787 2.98816C10.9456 2.75507 10.6486 2.59633 10.3253 2.53203C10.002 2.46772 9.6669 2.50072 9.36236 2.62687C9.05781 2.75301 8.79752 2.96664 8.61438 3.24072C8.43125 3.5148 8.3335 3.83703 8.3335 4.16667C8.3335 4.60869 8.50909 5.03262 8.82165 5.34518C9.13421 5.65774 9.55814 5.83333 10.0002 5.83333ZM10.0002 14.1667C9.67053 14.1667 9.3483 14.2644 9.07421 14.4476C8.80013 14.6307 8.58651 14.891 8.46036 15.1955C8.33422 15.5001 8.30121 15.8352 8.36552 16.1585C8.42983 16.4818 8.58857 16.7788 8.82165 17.0118C9.05474 17.2449 9.35171 17.4037 9.67501 17.468C9.99832 17.5323 10.3334 17.4993 10.638 17.3731C10.9425 17.247 11.2028 17.0334 11.3859 16.7593C11.5691 16.4852 11.6668 16.163 11.6668 15.8333C11.6668 15.3913 11.4912 14.9674 11.1787 14.6548C10.8661 14.3423 10.4422 14.1667 10.0002 14.1667ZM10.0002 8.33333C9.67053 8.33333 9.3483 8.43108 9.07421 8.61422C8.80013 8.79735 8.58651 9.05765 8.46036 9.3622C8.33422 9.66674 8.30121 10.0018 8.36552 10.3252C8.42983 10.6485 8.58857 10.9454 8.82165 11.1785C9.05474 11.4116 9.35171 11.5703 9.67501 11.6346C9.99832 11.699 10.3334 11.6659 10.638 11.5398C10.9425 11.4137 11.2028 11.2 11.3859 10.926C11.5691 10.6519 11.6668 10.3296 11.6668 10C11.6668 9.55797 11.4912 9.13405 11.1787 8.82149C10.8661 8.50893 10.4422 8.33333 10.0002 8.33333Z"
      fill="currentColor"
    />
  </svg>
);

export default More;
