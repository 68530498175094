import { SVGProps } from 'react';

const Copy = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10.667 17.3333H2.33366C1.88705 17.3488 1.45405 17.1782 1.13806 16.8622C0.822075 16.5462 0.651469 16.1132 0.666989 15.6666V7.33329C0.651469 6.88669 0.822075 6.45369 1.13806 6.1377C1.45405 5.82171 1.88705 5.6511 2.33366 5.66662H5.66699V2.33329C5.65147 1.88669 5.82208 1.45368 6.13806 1.1377C6.45405 0.821709 6.88705 0.651103 7.33366 0.666623H15.667C16.1136 0.651103 16.5466 0.821709 16.8626 1.1377C17.1786 1.45368 17.3492 1.88669 17.3337 2.33329V10.6666C17.3489 11.1132 17.1782 11.546 16.8623 11.8619C16.5464 12.1779 16.1135 12.3486 15.667 12.3333H12.3337V15.6666C12.3489 16.1132 12.1782 16.546 11.8623 16.8619C11.5464 17.1779 11.1135 17.3486 10.667 17.3333ZM2.33366 7.33329V15.6666H10.667V12.3333H7.33366C6.88712 12.3486 6.45427 12.1779 6.13834 11.8619C5.82241 11.546 5.65172 11.1132 5.66699 10.6666V7.33329H2.33366ZM7.33366 2.33329V10.6666H15.667V2.33329H7.33366Z"
      fill="currentColor"
    />
  </svg>
);

export default Copy;
