import { SVGProps } from 'react';

const Back = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="20"
    height="20"
    viewBox="0 -2 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10.3566 13.6562L4.69995 7.99958L10.3566 2.34292L11.3 3.28558L6.58528 7.99958L11.3 12.7136L10.3566 13.6562Z"
      fill="currentColor"
    />
  </svg>
);

export default Back;
