import { SVGProps } from 'react';

const PhoneInfo = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="55"
    height="10"
    viewBox="0 0 55 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      opacity="0.35"
      d="M35.2003 3.00015C35.2003 2.04286 35.9763 1.26682 36.9336 1.26682H50.267C51.2243 1.26682 52.0003 2.04286 52.0003 3.00015V7.80016C52.0003 8.75745 51.2243 9.53349 50.267 9.53349H36.9336C35.9763 9.53349 35.2003 8.75745 35.2003 7.80015V3.00015Z"
      stroke="currentColor"
      strokeWidth="0.8"
    />
    <path
      opacity="0.4"
      d="M53.2002 3.80017V7.00017C53.844 6.72915 54.2626 6.09868 54.2626 5.40017C54.2626 4.70166 53.844 4.07119 53.2002 3.80017Z"
      fill="currentColor"
    />
    <path
      d="M36.4004 3.53346C36.4004 2.94436 36.878 2.4668 37.4671 2.4668H49.7337C50.3228 2.4668 50.8004 2.94436 50.8004 3.53346V7.2668C50.8004 7.8559 50.3228 8.33346 49.7337 8.33346H37.4671C36.878 8.33346 36.4004 7.8559 36.4004 7.2668V3.53346Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24.6645 2.68649C26.4372 2.68656 28.1421 3.36771 29.4269 4.58915C29.5236 4.68345 29.6782 4.68226 29.7735 4.58649L30.6983 3.65315C30.7466 3.60457 30.7735 3.53877 30.7731 3.47031C30.7727 3.40184 30.745 3.33636 30.6962 3.28835C27.3241 0.0567145 22.0043 0.0567145 18.6322 3.28835C18.5833 3.33633 18.5556 3.40179 18.5552 3.47025C18.5547 3.53872 18.5816 3.60454 18.6298 3.65315L19.5549 4.58649C19.6501 4.68241 19.8048 4.6836 19.9015 4.58915C21.1864 3.36763 22.8916 2.68648 24.6645 2.68649ZM24.6643 5.72309C25.6383 5.72303 26.5775 6.08506 27.2995 6.73882C27.3972 6.83161 27.551 6.8296 27.6462 6.73429L28.5699 5.80096C28.6186 5.752 28.6455 5.68559 28.6448 5.61658C28.6441 5.54756 28.6158 5.48171 28.5662 5.43376C26.3676 3.38865 22.9629 3.38865 20.7643 5.43376C20.7146 5.48171 20.6863 5.5476 20.6857 5.61663C20.685 5.68566 20.7121 5.75207 20.7608 5.80096L21.6843 6.73429C21.7795 6.8296 21.9333 6.83161 22.031 6.73882C22.7525 6.08549 23.691 5.72349 24.6643 5.72309ZM26.5148 7.76614C26.5162 7.83535 26.489 7.90207 26.4396 7.95055L24.8417 9.56309C24.7949 9.61048 24.731 9.63715 24.6644 9.63715C24.5977 9.63715 24.5339 9.61048 24.487 9.56309L22.8889 7.95055C22.8395 7.90203 22.8124 7.83529 22.8138 7.76608C22.8153 7.69688 22.8453 7.63134 22.8966 7.58495C23.9171 6.72185 25.4116 6.72185 26.4321 7.58495C26.4834 7.63138 26.5133 7.69694 26.5148 7.76614Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.7336 1.13342H12.9336C12.4918 1.13342 12.1336 1.4916 12.1336 1.93342V8.86676C12.1336 9.30858 12.4918 9.66676 12.9336 9.66676H13.7336C14.1754 9.66676 14.5336 9.30858 14.5336 8.86676V1.93342C14.5336 1.4916 14.1754 1.13342 13.7336 1.13342ZM9.20039 3.00012H10.0004C10.4422 3.00012 10.8004 3.35829 10.8004 3.80012V8.86679C10.8004 9.30862 10.4422 9.66679 10.0004 9.66679H9.20039C8.75856 9.66679 8.40039 9.30862 8.40039 8.86679V3.80012C8.40039 3.35829 8.75856 3.00012 9.20039 3.00012ZM6.2668 4.86672H5.4668C5.02497 4.86672 4.6668 5.2249 4.6668 5.66672V8.86672C4.6668 9.30855 5.02497 9.66672 5.4668 9.66672H6.2668C6.70862 9.66672 7.0668 9.30855 7.0668 8.86672V5.66672C7.0668 5.2249 6.70862 4.86672 6.2668 4.86672ZM2.53359 6.46672H1.73359C1.29177 6.46672 0.933594 6.8249 0.933594 7.26672V8.86672C0.933594 9.30855 1.29177 9.66672 1.73359 9.66672H2.53359C2.97542 9.66672 3.33359 9.30855 3.33359 8.86672V7.26672C3.33359 6.8249 2.97542 6.46672 2.53359 6.46672Z"
      fill="currentColor"
    />
  </svg>
);

export default PhoneInfo;
