import { SVGProps } from 'react';

const CircleMinus = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="17"
    height="16"
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8.98991 1.33325C7.67137 1.33325 6.38244 1.72425 5.28611 2.45679C4.18978 3.18933 3.3353 4.23052 2.83071 5.4487C2.32613 6.66687 2.19411 8.00731 2.45134 9.30052C2.70858 10.5937 3.34352 11.7816 4.27587 12.714C5.20822 13.6463 6.3961 14.2813 7.68931 14.5385C8.98252 14.7957 10.323 14.6637 11.5411 14.1591C12.7593 13.6545 13.8005 12.8 14.533 11.7037C15.2656 10.6074 15.6566 9.31846 15.6566 7.99992C15.6566 7.12444 15.4841 6.25753 15.1491 5.4487C14.8141 4.63986 14.323 3.90493 13.704 3.28587C13.0849 2.66682 12.35 2.17575 11.5411 1.84072C10.7323 1.50569 9.86539 1.33325 8.98991 1.33325ZM8.98991 13.3333C7.93508 13.3333 6.90393 13.0205 6.02687 12.4344C5.14981 11.8484 4.46622 11.0154 4.06255 10.0409C3.65889 9.06636 3.55327 7.994 3.75906 6.95944C3.96484 5.92487 4.4728 4.97456 5.21868 4.22868C5.96456 3.4828 6.91487 2.97485 7.94943 2.76906C8.984 2.56328 10.0564 2.66889 11.0309 3.07256C12.0054 3.47623 12.8384 4.15982 13.4244 5.03688C14.0104 5.91394 14.3232 6.94509 14.3232 7.99992C14.3232 9.41441 13.7613 10.771 12.7611 11.7712C11.761 12.7713 10.4044 13.3333 8.98991 13.3333ZM11.6566 7.33325H6.32324C6.14643 7.33325 5.97686 7.40349 5.85184 7.52851C5.72682 7.65354 5.65658 7.82311 5.65658 7.99992C5.65658 8.17673 5.72682 8.3463 5.85184 8.47132C5.97686 8.59635 6.14643 8.66659 6.32324 8.66659H11.6566C11.8334 8.66659 12.003 8.59635 12.128 8.47132C12.253 8.3463 12.3232 8.17673 12.3232 7.99992C12.3232 7.82311 12.253 7.65354 12.128 7.52851C12.003 7.40349 11.8334 7.33325 11.6566 7.33325Z"
      fill="currentColor"
    />
  </svg>
);

export default CircleMinus;
